class Entity {
  constructor(attrs) {
    Object.assign(this, attrs);
  }
}

class SuperAdmin extends Entity {
  static modelName = 'SuperAdmin';
}

class VendorAdmin extends Entity {
  static modelName = 'VendorAdmin';
}

export default {
  SuperAdmin,
  VendorAdmin
};
