<template>
  <div class="d-flex flex-column align-center">
    <img :src="imageSrc" :height="200" />
    <div class="text-h6 mt-6">{{ message }}</div>
  </div>
</template>

<script>
import emptyStateDefault from '@/assets/illustrations/empty-state-desert.svg';
export default {
  name: 'CzEmptyState',
  props: {
    message: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      default: emptyStateDefault
    }
  }
};
</script>

<style></style>
