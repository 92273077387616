export const ReaderType = {
  TEXT: 'readAsText',
  URL: 'readAsDataURL',
  BINARY: 'readAsBinaryString',
  ARRAY_BUFFER: 'readAsArrayBuffer'
};

export function fileReaderPromise(file, readerType = ReaderType.URL) {
  // if reader type sent by the user is not supported
  // then we return null
  if (!readerType) {
    return null;
  }

  const reader = new FileReader();
  if (!reader[readerType]) {
    return null;
  }

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Error while trying to read input file'));
    };

    reader.onload = () => {
      resolve(reader.result);
    };

    reader[readerType](file);
  });
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}
