<template>
  <v-icon
    :color="color"
    :size="size"
    :small="small"
    :large="large"
    :x-large="xLarge"
    :left="left"
    :right="right"
    v-bind="$attrs"
    v-on="$listeners"
    >{{ src }}</v-icon
  >
</template>

<script>
export default {
  name: 'CzIcon',
  props: {
    src: {
      type: String
    },
    color: {
      type: String,
      default: 'inherit'
    },
    size: {
      type: [String, Number],
      default: 18
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
