<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :open-on-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :nudge-top="25"
  >
    <template v-slot:activator="{ on, attrs }">
      <cz-input
        v-model="inputDate"
        v-bind="attrs"
        v-on="on"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        outlined
        :clearable="!readonly"
        :dense="dense"
        :disabled="disabled"
        :append-icon="!readonly ? mdiCalendar : null"
        @click:append="dateMenu = true"
        @click="onDateInputClicked"
        :readonly="readonly"
        validate-on-blur
        :hide-details="hideDetails"
        @blur="$emit('blur')"
        @click:clear="onDateChanged('')"
        v-mask="mask"
      />
    </template>

    <v-date-picker
      v-model="date"
      :active-picker.sync="activePicker"
      :max="maxDate"
      :min="minDate"
      :prev-icon="mdiChevronLeft"
      :next-icon="mdiChevronRight"
      validate-on-blur
      :readonly="readonly"
      :type="type"
      @change="onDateChanged"
      color="primary"
      locale="he"
    />
  </v-menu>
</template>

<script>
import CzInput from './CzInput';
import { mdiCalendar, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { format, parse, isValid, parseISO, formatISO } from 'date-fns';

export default {
  name: 'CzDatePicker',
  components: {
    CzInput
  },
  props: {
    /**
     * The current date value
     * in ISO8601 format YYYY-mm-dd or YYYY-mm
     */
    value: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    minDate: {
      type: String
    },
    maxDate: {
      type: String
    },
    hideDetails: {
      type: Boolean
    },
    openMenuOnClick: {
      type: Boolean
    },
    type: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    /**
     * if this is true then the picker will start with year then month and then day
     * to allow the user to easily pick a date
     * otherwise, the date picker will be opended in a day mode
     */
    interactive: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mask() {
      if (this.type === 'month') {
        return '##/####';
      } else {
        return '##/##/####';
      }
    }
  },
  created() {
    if (this.value) {
      if (this.value instanceof Date && !isNaN(this.value)) {
        this.inputDate = format(this.value, this.dateFormat);
        this.date = format(this.value, 'yyyy-MM-dd');
        this.localDate = this.date;
      } else {
        let date = parse(this.value, this.dateFormat, new Date());
        this.inputDate = format(date, this.dateFormat);
        this.date = format(date, 'yyyy-MM-dd');
        this.localDate = this.date;
      }
    }
  },
  data() {
    return {
      mdiCalendar,
      mdiChevronLeft,
      mdiChevronRight,
      activePicker: null,
      date: null,
      localDate: null,
      inputDate: '',
      dateMenu: false
    };
  },
  methods: {
    onDateChanged(dateString) {
      this.dateMenu = false;
      if (!dateString) {
        this.inputDate = '';
        this.date = null;
      } else {
        this.inputDate = format(new Date(dateString), this.dateFormat);
      }
    },
    onDateInputClicked() {
      this.dateMenu = this.openMenuOnClick;
    }
  },
  watch: {
    inputDate(val) {
      if (isValid(parse(val, this.dateFormat, new Date()))) {
        const d = parse(val, this.dateFormat, new Date());
        this.date = format(d, 'yyyy-MM-dd');
      }

      if (this.date !== this.localDate) {
        this.$emit('input', val);
      }
    },
    dateMenu(val) {
      // in interactive mode we start with year, then month and then day
      if (this.interactive) {
        val && setTimeout(() => (this.activePicker = 'YEAR'));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
