<template>
  <v-text-field
    v-model="inputValue"
    v-bind="$attrs"
    v-on="$listeners"
    :full-width="fullWidth"
    :label="label"
    v-if="!multiLine || isSecured"
    :color="color"
    @keydown="onKeyDown"
    :style="inputStyle"
    :placeholder="placeholder"
    :outlined="outlined && !readonly"
    :append-icon="
      type === 'password'
        ? showSecureText
          ? mdiEyeOutline
          : mdiEyeOffOutline
        : appendIcon
    "
    @click:append="showSecureText = !showSecureText"
    :type="showSecureText ? 'text' : type"
    :counter="counter && !readonly"
    :flat="readonly"
    :solo="readonly"
    :readonly="readonly"
    :background-color="readonly ? 'transparent' : undefined"
    :clear-icon="mdiCloseCircleOutline"
    :hide-details="hideDetails"
    :loader-height="5"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-text-field>

  <v-textarea
    v-else
    v-model="inputValue"
    v-bind="$attrs"
    :full-width="fullWidth"
    v-on="$listeners"
    :label="label"
    @keydown="onKeyDown"
    :color="color"
    :rows="rows"
    :auto-grow="autoGrow"
    :style="inputStyle"
    :placeholder="placeholder"
    :no-resize="noResize"
    :outlined="outlined && !readonly"
    :counter="counter && !readonly"
    :flat="readonly"
    :solo="readonly"
    :readonly="readonly"
    :hide-details="hideDetails"
    :loader-height="5"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-textarea>
</template>

<script>
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiClose,
  mdiCloseCircleOutline
} from '@mdi/js';
export default {
  name: 'CzInput',
  components: {},
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String
    },
    maxLength: {
      type: Number,
      default: 0 // 0 means unlimited
    },
    color: {
      type: String,
      default: 'primary'
    },
    multiLine: {
      type: Boolean, // for multi line we use text area
      default: false
    },
    rows: {
      type: [Number, String],
      default: 5
    },
    autoGrow: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number
    },
    fullWidth: {
      type: Boolean
    },
    borderRadius: {
      type: Number
    },
    outlined: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * For text area fields only
     */
    noResize: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    counter: {
      type: Number,
      default: undefined
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: undefined
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    inputStyle() {
      return `
        border-radius: ${this.borderRadius}px
      `;
    },
    isSecured() {
      return this.type === 'password';
    }
  },
  data() {
    return {
      mdiEyeOutline,
      mdiClose,
      mdiCloseCircleOutline,
      mdiEyeOffOutline,
      showSecureText: false
    };
  },
  methods: {
    onKeyDown(evt) {
      // if max length is 0 or less then it means
      // that it is unlimited
      if (this.maxLength <= 0) {
        return;
      }

      // on key down we check if the current input value
      // is equal or larger than max length
      // if it is then we simply prevent the event default
      if (this.value?.length >= this.maxLength) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          evt.preventDefault();
          return;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
