<template>
  <cz-input
    v-model="date"
    v-on="$listeners"
    v-bind="$attrs"
    v-mask="dateMask"
    :placeholder="dateFormat.toLowerCase()"
  />
</template>

<script>
import CzInput from './CzInput.vue';
import { format } from 'date-fns';
export default {
  name: 'CzDateInput',
  components: {
    CzInput
  },
  props: {
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    dateMask: {
      type: String,
      default: '##/##/####'
    },
    value: {
      type: String,
      required: true
    }
  },
  created() {
    if (this.value) {
      this.date = format(new Date(this.value), this.dateFormat);
    }
  },
  data() {
    return {
      date: ''
    };
  },
  watch: {
    date: {
      handler(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style></style>
