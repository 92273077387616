<template>
  <div class="d-flex" style="column-gap: 10px">
    <cz-input :value="amount" type="number" />
    <v-select :value="currency" :items="currencies" />
  </div>
</template>

<script>
import CzInput from './CzInput.vue';
export default {
  name: 'CzAmountInput',
  components: {
    CzInput
  },
  props: {
    amount: {
      type: Number
    },
    currency: {
      type: String
    },
    currencies: {
      type: Array,
      default: () => {
        return [
          {
            text: 'ILS',
            value: 'ILS'
          },
          {
            text: 'USD',
            value: 'USD'
          },
          {
            text: 'EUR',
            value: 'EUR'
          }
        ];
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
