<template>
  <v-card flat rounded="lg" outlined style="overflow-y: auto">
    <v-card-text class="pa-0">
      <v-data-table
        v-on="$listeners"
        v-bind="$attrs"
        :no-data-text="noDataText || $t('common.noData')"
        :loading-text="$t('common.loading')"
        :headers="styledColumns"
        :header-props="{
          sortIcon: mdiArrowDown
        }"
        :height="height"
        :expand-icon="mdiChevronDown"
        :footer-props="{
          itemsPerPageOptions: [2, 5, 10, 15, 25, 50],
          nextIcon: mdiChevronRight,
          prevIcon: mdiChevronLeft,
          lastIcon: mdiChevronDoubleLeft,
          firstIcon: mdiChevronDoubleRight,
          itemsPerPageText: $t('common.itemsPerPage'),
          pageText: $t('common.pageText')
        }"
        :items-per-page="25"
      >
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

        <!-- Pass on all scoped slots -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiArrowDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDown,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight
} from '@mdi/js';
export default {
  name: 'CzDataTable',
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    noDataText: {
      type: String
    },
    height: {
      type: [String, Number]
    }
  },
  computed: {
    styledColumns() {
      return this.columns.map((column) => {
        return {
          ...column,
          class: 'font-weight-semibold text-body-1 primary--text'
        };
      });
    }
  },
  data() {
    return {
      mdiChevronDown,
      mdiChevronRight,
      mdiChevronLeft,
      mdiChevronDoubleLeft,
      mdiChevronDoubleRight,
      mdiArrowDown
    };
  }
};
</script>

<style></style>
