import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        background: '#FFF4FA',
        primary: '#F48FB1',
        accent: '#FF99A0',
        surface: '#FFFFFF',
        textPrimary: '#212121',
        negative: '#EC6973',
        error: '#EC6973',
        info: '#004b84',
        positive: '#4CAF50',
        warning: '#FFAA8B',
        button: '#004b84',
        buttonSecondary: '#79c9fa'
      }
    }
  }
});
