<template>
  <v-chip
    :dark="dark"
    :close="removeable"
    :label="!circleEdges"
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
    :small="small"
    :close-icon="mdiClose"
    @click:close="$emit('remove')"
  >
    <slot>
      <v-avatar v-if="avatarSrc">
        <img :src="avatarSrc" />
      </v-avatar>
      <div class="text-truncate font-weight-medium">
        {{ text }}
      </div>
    </slot>
  </v-chip>
</template>

<script>
import { mdiClose } from '@mdi/js';
export default {
  name: 'CzChip',
  props: {
    text: {
      type: String
    },
    color: {
      type: String
    },
    dark: {
      type: Boolean,
      default: false
    },
    circleEdges: {
      type: Boolean,
      default: true
    },
    removeable: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    avatarSrc: {
      type: String
    }
  },
  data() {
    return {
      mdiClose
    };
  }
};
</script>

<style lang="scss" scoped></style>
