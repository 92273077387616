<template>
  <v-avatar
    v-bind="$attrs"
    v-on="$listeners"
    :color="src ? undefined : color"
    :size="size"
    :tile="tile"
    :style="avatarStyle"
  >
    <div v-if="!src">
      <span :class="initialsClass">{{ fullNameInitials(name) }}</span>
    </div>
    <v-img v-else :src="src" :aspect-ratio="1" />
  </v-avatar>
</template>

<script>
export default {
  name: 'VzAvatar',
  props: {
    src: {
      type: String
    },
    // initials of the name will be extracted
    // in case src will be not given
    name: {
      type: String
    },
    size: {
      type: Number,
      default: 48
    },
    color: {
      type: String,
      default: 'primary'
    },
    initialsColor: {
      type: String,
      default: 'textPrimary'
    },
    initialsSize: {
      type: String
    },
    // tile is square avatar
    // if tile is false then avatar will be circle
    tile: {
      type: Boolean,
      default: false
    },
    // when tile is true border radius can be applied
    borderRadius: {
      type: Number,
      default: 4
    }
  },
  computed: {
    initialsClass() {
      return {
        [`${this.initialsColor}--text`]: !!this.initialsColor,
        [`${this.initialsSize}`]: !!this.initialsSize
      };
    },
    avatarStyle() {
      if (this.tile) {
        return `
            border-radius: ${this.borderRadius}px !important;
        `;
      } else {
        return '';
      }
    }
  },
  methods: {
    fullNameInitials(name) {
      if (!name) {
        return '';
      }
      const firstName = name.split(' ').slice(0, -1).join(' ');
      const lastName = name.split(' ').slice(-1).join(' ');

      return this.nameInitials(firstName, lastName);
    },
    nameInitials(firstName, lastName) {
      let initials = '';

      if (firstName && firstName.length) {
        initials = firstName.charAt(0);
      }

      if (lastName && lastName.length) {
        initials += lastName.charAt(0);
      }

      return initials;
    }
  }
};
</script>

<style lang="scss" scoped></style>
