<template>
  <v-alert
    v-bind="$attrs"
    v-on="$listeners"
    :close-icon="mdiCloseCircleOutline"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-alert>
</template>

<script>
import { mdiCloseCircleOutline } from '@mdi/js';
export default {
  data() {
    return {
      mdiCloseCircleOutline
    };
  }
};
</script>

<style lang="scss" scoped></style>
