<template>
  <v-card
    :height="height"
    :width="width"
    hover
    outlined
    rounded="lg"
    @click="$emit('click')"
  >
    <img :src="imageSrc" width="100%" />
    <v-card-title class="text-truncate text-no-wrap">{{ title }}</v-card-title>
    <v-card-subtitle>
      <cz-chip
        small
        color="primary lighten-2"
        text-color="textPrimary"
        v-if="count"
        >{{ count }}</cz-chip
      >
    </v-card-subtitle>
    <v-card-text v-if="subtitle">{{ subtitle }}</v-card-text>
  </v-card>
</template>

<script>
import { CzChip } from '@/components';
export default {
  name: 'CzDashboardCard',
  components: {
    CzChip
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    count: {
      type: [String, Number]
    },
    imageSrc: {
      type: String,
      required: true
    },
    width: {
      type: String | Number,
      default: 240
    },
    height: {
      type: String | Number,
      default: 310
    }
  },
  computed: {
    cardHeight() {
      if (this.count) {
        return (this.height += 20);
      } else {
        return this.height;
      }
    }
  }
};
</script>

<style></style>
