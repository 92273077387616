<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :open-on-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :nudge-top="25"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        v-bind="attrs"
        v-on="on"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        :outlined="!readonly"
        :flat="readonly"
        :solo="readonly"
        :clearable="!readonly"
        :dense="dense"
        :disabled="disabled"
        :append-icon="!readonly ? mdiCalendar : null"
        @click:append="dateMenu = true"
        @click="onDateInputClicked"
        validate-on-blur
        :hide-details="hideDetails"
        readonly
        @blur="$emit('blur')"
        @click:clear="onDateChanged('')"
      />
    </template>

    <v-date-picker
      v-model="date"
      :active-picker.sync="activePicker"
      :max="maxDate"
      :min="minDate"
      :prev-icon="mdiChevronLeft"
      :next-icon="mdiChevronRight"
      validate-on-blur
      :readonly="readonly"
      @change="onDateChanged"
      :type="type"
      color="primary"
      locale="he"
    />
  </v-menu>
</template>

<script>
import CzInput from './CzInput';
import { mdiCalendar, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { format, parse } from 'date-fns';

export default {
  name: 'CzNewDatePicker',
  components: {
    CzInput
  },
  props: {
    /**
     * The current date value
     * in ISO8601 format YYYY-mm-dd or YYYY-mm
     */
    value: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    minDate: {
      type: String
    },
    maxDate: {
      type: String
    },
    hideDetails: {
      type: Boolean
    },
    openMenuOnClick: {
      type: Boolean
    },
    type: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    /**
     * if this is true then the picker will start with year then month and then day
     * to allow the user to easily pick a date
     * otherwise, the date picker will be opended in a day mode
     */
    interactive: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // formattedDate: {
    //   set(val) {
    //     this.date = val;
    //   },
    //   get() {
    //     if (!this.date) {
    //       return '';
    //     } else {
    //       let parsedDate = parse(this.date, 'yyyy-MM-dd', new Date());
    //       return format(parsedDate, this.dateFormat);
    //     }
    //   }
    // }
    formattedDate() {
      if (!this.date) {
        return '';
      } else {
        let parsedDate = parse(this.date, 'yyyy-MM-dd', new Date());
        return format(parsedDate, this.dateFormat);
      }
    },
    mask() {
      if (this.type === 'month') {
        return '##/####';
      } else {
        return '##/##/####';
      }
    }
  },
  created() {
    this.date = this.value;
  },
  data() {
    return {
      mdiCalendar,
      mdiChevronLeft,
      mdiChevronRight,
      activePicker: null,
      date: null,
      localDate: null,
      inputDate: '',
      dateMenu: false
    };
  },
  methods: {
    onDateChanged(dateString) {
      this.dateMenu = false;
      // if (!dateString) {
      //   this.inputDate = '';
      //   this.date = null;
      // } else {
      //   this.inputDate = format(new Date(dateString), this.dateFormat);
      // }
    },
    onDateInputClicked() {
      this.dateMenu = this.openMenuOnClick && !this.readonly;
    }
  },
  watch: {
    date(val) {
      this.$emit('input', val);
    }
  }
};
</script>

<style lang="scss" scoped></style>
