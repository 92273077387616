<template>
  <v-app>
    <v-main class="background">
      <router-view v-if="isTermsAccepted" />
    </v-main>
    <cz-toast
      v-model="toast.show"
      v-if="toast && toast.message"
      :message="toast.message"
      :color="toast.color"
      :timeout="toast.timeout"
    />

    <terms-dialog
      v-model="termsDialog.show"
      v-if="termsDialog.show"
      v-bind="termsDialog"
      :closeable="false"
      @on-confirm="onTermsConfirmed"
    />

    <cz-prompt-dialog
      v-if="prompt.show"
      v-model="prompt.show"
      v-bind="prompt"
      hide-cancel-button
      @done="prompt.show = false"
      title-color="error"
      :title="$t('common.errorOccured')"
    />
  </v-app>
</template>

<script>
import logo from '@/assets/corz.png';
import { CzToast, CzPromptDialog } from '@/components';
import { mapFields } from 'vuex-map-fields';
import TermsDialog from './domain/auth/components/TermsDialog.vue';
import { updateUser } from './core/api';

export default {
  name: 'App',
  components: {
    CzToast,
    TermsDialog,
    CzPromptDialog
  },
  computed: {
    ...mapFields('ui', ['toast', 'prompt']),
    ...mapFields('auth', ['currentUser']),
    isTermsAccepted() {
      if (!this.currentUser) {
        return true;
      }

      return this.currentUser.termsAccepted;
    }
  },
  data: () => ({
    logo,
    showNav: false,
    termsDialog: {
      show: false,
      loading: false
    }
  }),
  methods: {
    async onTermsConfirmed() {
      try {
        this.termsDialog.loading = true;
        this.currentUser = await updateUser(this.currentUser._id, {
          termsAccepted: true
        });
        this.termsDialog.show = false;
      } finally {
        this.termsDialog.loading = false;
      }
    }
  },
  watch: {
    currentUser: {
      handler(value) {
        if (value && !value.termsAccepted) {
          this.termsDialog.show = true;
        }
      }
    }
  }
};
</script>
