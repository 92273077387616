import { getField, updateField } from 'vuex-map-fields';
import { getAccountWorkflows, getCatalogItems } from '@/core/api';

const state = {
  currentOrdersTabId: '',
  accountWorkflows: null,
  catalogItems: null
};

const getters = {
  getField
};

const mutations = {
  updateField
};

const actions = {
  async loadAccountWorkflows({ commit, state }, accountId) {
    if (state.accountWorkflows === null) {
      const { data } = await getAccountWorkflows({
        accountId
      });
      commit('updateField', {
        path: 'accountWorkflows',
        value: data
      });
    }
  },
  async loadCatalogItems({ commit, state, rootState }, catalogId = 1) {
    if (state.catalogItems === null) {
      const { data } = await getCatalogItems({
        accountId: rootState.auth?.accountId,
        isArchived: false,
        catalogId
      });

      commit('updateField', {
        path: 'catalogItems',
        value: data
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
