<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :title="$t('common.terms')"
    :width="800"
    show-actions
  >
    <v-card flat height="calc(100vh - 300px)" style="overflow-y: auto">
      <pdf-viewer :source="termsUrl" />
    </v-card>
    <template #actions>
      <v-footer
        :height="50"
        width="100%"
        class="justify-space-between overflow-hidden align-center"
      >
        <cz-checkbox
          :input-value="accepted"
          :label="$t('terms.readAndUnderstand')"
          @change="onAcceptChange"
          :disabled="loading"
        />
        <cz-button
          :title="$t('terms.confirmButton')"
          color="primary"
          :disabled="!accepted"
          :loading="loading"
          @click="$emit('on-confirm')"
        />
      </v-footer>
    </template>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzButton, CzCheckbox } from '@/components';
import PdfViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
export default {
  name: 'TermsDialog',
  components: {
    CzButton,
    CzDialog,
    CzCheckbox,
    PdfViewer
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      accepted: false,
      termsUrl: 'https://storage.googleapis.com/corz-files-public/terms.pdf'
    };
  },
  methods: {
    onAcceptChange(value) {
      this.accepted = value;
    }
  }
};
</script>

<style></style>
