<template>
  <v-btn
    v-on="$listeners"
    v-bind="$attrs"
    :color="color"
    :dark="dark"
    :text="text"
    :width="width"
    :disabled="disabled"
    :min-width="minWidth"
    :max-width="maxWidth"
    :height="height"
    :min-height="minHeight"
    :max-height="maxHeight"
    :outlined="outlined"
    :loading="loading"
    :plain="plain"
    :rounded="rounded"
    :ripple="ripple"
    :elevation="0"
    class="text-capitalize"
    :class="{
      'hover-effect-remove': !hoverEffect,
      'pa-0': noPaddings
    }"
    :title="tooltip"
  >
    <slot>
      <cz-icon
        v-if="iconSrc && iconLocation === 'prepend'"
        :size="iconSize"
        :color="iconColor"
        :src="iconSrc"
        :left="title && iconSrc ? true : false"
      />
      <template v-if="title">{{ title }}</template>
      <cz-icon
        v-if="iconSrc && iconLocation === 'append'"
        :size="iconSize"
        :color="iconColor"
        :src="iconSrc"
        right
      />
    </slot>
  </v-btn>
</template>

<script>
import CzIcon from './CzIcon';
export default {
  name: 'CzButton',
  components: {
    CzIcon
  },
  props: {
    color: {
      type: String
    },
    rounded: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean
    },
    dark: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number]
    },
    maxWidth: {
      type: [String, Number]
    },
    minWidth: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    },
    maxHeight: {
      type: [String, Number]
    },
    minHeight: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    tooltip: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      // make the button with transparent background and show text only
      type: Boolean,
      default: false
    },
    iconSrc: {
      type: String
    },
    iconSize: {
      type: [String, Number],
      default: 18
    },
    iconColor: {
      type: String
    },
    iconLocation: {
      type: String,
      default: 'prepend'
    },
    hoverEffect: {
      type: Boolean,
      default: true
    },
    plain: {
      type: Boolean,
      default: false
    },
    ripple: {
      type: Boolean,
      default: true
    },
    noPaddings: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.hover-effect-remove::before {
  background-color: transparent !important;
}
</style>
