import { getField, updateField } from 'vuex-map-fields';

const state = {
  filtersState: {
    tab: -1,
    currentFilters: null,
    options: {}
  }
};

const getters = {
  getField
};

const mutations = {
  updateField
};

const actions = {
  resetFiltersState({ commit }) {
    commit('updateField', {
      path: 'filtersState',
      value: {
        tab: -1,
        currentFilters: null,
        options: {}
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
