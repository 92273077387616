import { getField, updateField } from 'vuex-map-fields';
const state = {
  toast: {
    show: false,
    message: '',
    color: undefined,
    timeout: 5000
  },
  prompt: {
    title: 'Error',
    show: false,
    message: '',
    color: 'error'
  }
};

const getters = {
  getField
};
const mutations = {
  updateField
};

const actions = {
  showToastMessage({ commit }, message) {
    commit('updateField', {
      path: 'toast',
      value: {
        show: true,
        color: undefined,
        message
      }
    });
  },
  showSuccessToastMessage({ commit }, message) {
    commit('updateField', {
      path: 'toast',
      value: {
        show: true,
        color: 'success',
        message
      }
    });
  },
  showErrorToastMessage({ commit }, message) {
    commit('updateField', {
      path: 'toast',
      value: {
        show: true,
        color: 'error',
        message
      }
    });
  },
  showErrorPrompt({ commit }, message) {
    commit('updateField', {
      path: 'prompt',
      value: {
        show: true,
        color: 'error',
        message
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
