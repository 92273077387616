import axios from 'axios';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';

export const BASE_URL = process.env.VUE_APP_CORZ_API_ENDPOINT;

export const storageKey = 'corz-token';

const app = feathers();

const restClient = rest(BASE_URL);
app.configure(restClient.axios(axios));

app.configure(
  auth({
    storage: window.localStorage,
    storageKey
  })
);

app.hooks({
  before: {
    all: [
      (context) => {
        if (context.path !== 'authentication') {
          context.params.headers = {
            ...context.params.headers,
            'Accept-Language': 'he'
          };
        }
      }
    ]
  }
});

export default app;

export function getRestClient() {
  return axios;
}

export function getService(name) {
  return app.service(name);
}
