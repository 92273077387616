import { getField, updateField } from 'vuex-map-fields';

const state = {
  invitation: null
};

const getters = {
  getField
};

const mutations = {
  updateField
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
