<template>
  <div
    class="d-flex flex-column align-center"
    :class="centerize && 'centerize'"
  >
    <v-progress-circular
      indeterminate
      v-bind="$attrs"
      :color="color"
      :size="size"
    />
    <div class="text-body-1 mt-4" :class="titleClass" v-if="showTitle">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CzSpinner',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: 'Loading...'
    },
    size: {
      type: [String, Number],
      default: 32
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    // if possible center the spinner vertically and horizontally on screen
    centerize: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleClass() {
      return `${this.color}--text`;
    }
  }
};
</script>

<style lang="scss" scoped>
.centerize {
  position: fixed;
  top: 50%;
  left: 50%;
}
</style>
