<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    app
    right
    top
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <cz-button
        :icon-src="mdiClose"
        v-bind="attrs"
        @click="show = false"
        text
      />
    </template>
  </v-snackbar>
</template>

<script>
import CzButton from './CzButton.vue';
import { mdiClose } from '@mdi/js';
export default {
  name: 'CzToast',
  components: {
    CzButton
  },
  props: {
    value: {
      type: Boolean
    },
    color: {
      type: String
    },
    message: {
      type: String,
      required: true
    },
    timeout: {
      type: [Number, String],
      default: 5000
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      mdiClose
    };
  }
};
</script>

<style lang="scss" scoped></style>
