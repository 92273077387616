<template>
  <div
    class="cz-dropzone align-center d-flex justify-center flex-column"
    :style="boxStyle"
    @drop="onFileDropped"
    @dragleave="onDragLeave"
    @dragover="onDragOver"
    :class="hovered ? 'primary lighten-3' : ''"
  >
    <slot name="top" />
    <div v-cloak class="d-flex align-center justify-center">
      <div
        class="text-subtitle-1 font-weight-medium"
        :class="textClass"
        v-if="!hideTitle"
      >
        {{ dropzoneTitle }}
        <a
          v-if="!readonly"
          href="javascript:void(0)"
          @blur="$emit('blur')"
          @click="onBrowseFileClicked"
          class="font-weight-semibold"
          >{{ $t('dropzone.selectButtonTitle') }}</a
        >
        <input
          @change="fileInputChange"
          type="file"
          ref="file"
          :multiple="multiple"
          style="display: none"
          :accept="accept"
        />
      </div>
    </div>
    <div class="width-90 d-flex justify-center">
      <slot name="files"></slot>
    </div>
  </div>
</template>

<script>
import {
  ReaderType,
  fileReaderPromise
} from '@/shared/services/fileReader.service';

export default {
  name: 'CzDropzone',
  props: {
    borderColor: {
      type: String,
      default: '#496C89'
    },
    title: {
      type: String
    },
    textColor: {
      type: String,
      default: 'textSecondary'
    },
    height: {
      type: [String, Number],
      default: 100
    },
    minHeight: {
      type: [String, Number],
      default: undefined
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // The file type we allow the user to upload
    // by default we allow all files
    // the user can override this value to allow other types
    accept: {
      type: String,
      default: '*'
    },
    // true if we allow user to upload multiple files
    // if this value is false and  the drop 2 files we will always take
    // the first one and ignore the rest
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * If  this flag is true then we will use photo cropper
     * after user will select the image
     * Please notice that if this flag is true then accept will only be image/*
     * and multiple will always be false
     */
    usePhotoCropper: {
      type: Boolean,
      default: false
    },

    cropperAspectRatio: {
      type: Number,
      default: NaN
    },
    /**
     * if use photo cropper is true
     * then the user can send a list of options for the photo cropper here
     * the structure must be the structure of the initial object
     */
    cropperOptions: {
      type: Object,
      default() {
        return {
          aspectRatio: this.cropperAspectRatio,
          zoomable: true,
          moveable: true,
          submitButtonTitle: 'Done',
          cancelButtonTitle: 'Cancel',
          outputQuality: 1,
          mimeType: 'image/jpeg'
        };
      }
    },
    /**
     * flag indicate if we want that dropzone will also use file reader
     * to read and return the file content
     * currently this flag can be true only if multiple flag is false
     * if multiple is true this flag will be ignored!
     */
    useFileReader: {
      type: Boolean,
      default: false
    },
    /**
     * In read only mode we will hide the browse button and
     * we will not allow drag and drop
     */
    readonly: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCropper: false,
      hovered: false
    };
  },
  computed: {
    dropzoneTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.$t('dropzone.title');
      }
    },
    boxStyle() {
      const height = isNaN(this.height) ? this.height : `${this.height}px`;
      const width = isNaN(this.width) ? this.width : `${this.width}px`;
      const opacity = this.disabled || this.hovered ? 0.5 : 1;

      const style = {
        'border-color': this.borderColor,
        height,
        width,
        opacity
      };

      if (this.minHeight) {
        const minHeight = isNaN(this.minHeight)
          ? this.minHeight
          : `${this.minHeight}px`;
        style['min-height'] = minHeight;
      }

      return style;
    },
    textClass() {
      return `${this.textColor}--text`;
    },
    multipleFilesAllowed() {
      return this.multiple && !this.usePhotoCropper;
    }
  },
  methods: {
    onBrowseFileClicked() {
      if (this.disabled) {
        return;
      }

      // if we use photo cropper then bz-photo-cropper will
      // allow the user to select the file
      if (this.usePhotoCropper) {
        this.showCropper = true;
      } else {
        // we're not using photo cropper so we will use the input file for browse for files
        this.$refs.file.click();
      }
    },
    onFileDropped(event) {
      event.preventDefault();
      this.hovered = false;
      if (this.disabled || this.readonly) {
        return;
      }

      const droppedFiles = Array.from(event.dataTransfer?.files);
      // here we need to compare the files types the user dropped with the accept
      // value since we cannot control on the accept in drag and drop mode
      // if the file is not supported then we simply remove it
      const filesToAdd = droppedFiles.filter((file) => {
        return this.isFileTypeValid(file.type);
      });

      if (this.usePhotoCropper) {
        this.$refs.cropper.loadFileToImageCropper(filesToAdd[0]);
      } else {
        this.addFiles(filesToAdd);
      }
    },
    onImageCroppedChange(file) {
      const fileToAdd = new File([file.blob], file.name, {
        type: file?.blob?.type
      });
      this.addFiles([fileToAdd]);
    },
    onDragLeave(event) {
      event.preventDefault();
      this.hovered = false;
    },
    onDragOver(event) {
      event.preventDefault();
      this.hovered = true;
    },
    async addFiles(files) {
      if (files?.length === 0) {
        return;
      }

      if (this.useFileReader) {
        const promises = [];
        Array.from(files).forEach((file) => {
          promises.push(fileReaderPromise(file, ReaderType.URL));
        });
        const results = await Promise.all(promises);
        const urls = results.map((url, index) => {
          return {
            url,
            file: files[index]
          };
        });
        this.$emit('change', urls);
      } else {
        this.$emit('change', files);
      }
      this.$refs.file.value = '';
    },
    fileInputChange(event) {
      const files = event.target.files;
      this.addFiles(files);
    },
    isFileTypeValid(type) {
      // if accept is * we allow all files
      if (this.accept === '*') {
        return true;
      }

      // parse the accpt attribute by comma
      let acceptableMimeTypes = this.accept.split(',');

      acceptableMimeTypes = acceptableMimeTypes.map((mimeType) =>
        mimeType.trim()
      );

      if (type.startsWith('image') && acceptableMimeTypes.includes('image/*')) {
        return true;
      }

      if (type.startsWith('video') && acceptableMimeTypes.includes('video/*')) {
        return true;
      }

      return acceptableMimeTypes.includes(type);
    }
  }
};
</script>

<style lang="scss" scoped>
.cz-dropzone {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border-width: 1px;
  border-style: dashed;
  overflow: hidden !important;
}
</style>
